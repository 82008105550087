import { Controller } from "stimulus";
import panzoom from "panzoom";

const LETTER_WIDTH = 696;
const LETTER_HEIGHT = 984;

export default class extends Controller {
  static targets = ["letter"];

  connect() {
    this.preview = panzoom(this.letterTarget, {
      maxZoom: 1.25,
      minZoom: 0.5,
      bounds: true,
      boundsPadding: 0.4,
    });

    this.center();
    window.addEventListener("resize", this.center);
  }

  center = () => {
    const initalZoom =
      this.element.clientWidth / LETTER_WIDTH <
      this.element.clientHeight / LETTER_HEIGHT
        ? this.element.clientWidth / LETTER_WIDTH
        : this.element.clientHeight / LETTER_HEIGHT;

    this.preview.zoomAbs(0, 0, initalZoom);
    this.preview.moveTo(
      (this.element.clientWidth - LETTER_WIDTH * initalZoom) / 2,
      (this.element.clientHeight - LETTER_HEIGHT * initalZoom) / 2,
    );
  };
}
