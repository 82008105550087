import { Controller } from "stimulus";
import { Toast } from "bootstrap";

export default class extends Controller {
  connect() {
    this.toast = new Toast(this.element);
    if (localStorage.getItem("consent_cookies_all")) return;

    setTimeout(() => {
      this.toast.show();
    }, 4000);
  }

  consent = () => {
    this.toast.hide();
    localStorage.setItem("consent_cookies_all", "1");

    if (process.env.RAILS_ENV === "production") {
      dataLayer.push({ event: "consent_cookies_all" });
    }
  };

  dismiss = () => {
    this.toast.hide();
    localStorage.setItem("consent_cookies_all", "0");

    if (process.env.RAILS_ENV === "production") {
      dataLayer.push({ event: "dismiss_cookies_all" });
    }
  };
}
