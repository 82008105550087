import { Controller } from "stimulus";
import { Datepicker } from "vanillajs-datepicker";
import fr from "vanillajs-datepicker/js/i18n/locales/fr.js";

Object.assign(Datepicker.locales, fr);

export default class extends Controller {
  connect() {
    new Datepicker(this.element, {
      format: "dd/mm/yyyy",
      language: "fr",
      title: "Sélectionnez une date",
      prevArrow: "<",
      nextArrow: ">",
      autohide: true,
    });
  }
}
